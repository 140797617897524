import { Home } from "./Sections/Home";
import { Services } from "./Sections/Services";
import "./ContactPage.less";

export const ContactPage = () => {
    return (
        <>
            <Home />
            <Services />
        </>
    );
};
