import ReactMarkdown from "react-markdown";
import { IProject } from "../../../../models/Project";
import Section from "../../../Section";

const Fade = require("react-reveal/Fade");

const ProjectScope: React.FC<{ project: IProject | null }> = (props) => {
    const { project } = props;
    return (
        <Section id="project-scope" className="scope" textColor="white">
            <p>
                <ReactMarkdown className="line-break" children={project?.attributes.scopeText.replaceAll(/\n/gi, "&nbsp; \n").replaceAll("{endList}", "\n\n") || ''}
                    components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                />
            </p>
            <Fade up>
                <img src={project?.attributes.scopeImage.data.attributes.url} alt="Project" />
            </Fade>
            <p>
                {project?.attributes.goal && (<ReactMarkdown className="line-break" children={project?.attributes.goal.replaceAll(/\n/gi, "&nbsp; \n").replaceAll("{endList}", "\n\n") || ''}
                    components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                />)}
            </p>
        </Section>
    );
}

export default ProjectScope;
