import { Carousel } from "react-responsive-carousel";
import { IQuote } from "../../models/Quote";
import { ReactComponent as QuoteLogo } from "./quotes_icon.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Quotes.less";

export interface IQuotesProps {
    quotes: IQuote[];
    color?: string;
}

export const Quotes = ({ quotes, color }: IQuotesProps) => {
    return (
        <Carousel
            className={`quotes-carousel ${color}`}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            interval={10000}
            autoPlay
        >
            {quotes.map((quote, index) => {
                return (
                    <div key={quote.name + index} className="quote">
                        <div className="quote-row">
                            <QuoteLogo />
                            <p>{quote.content}</p>
                        </div>

                        <div className="quote-signature">
                            <div className="quote-name">{quote.name}</div>
                            <div>{quote.credentials}</div>
                        </div>
                    </div>
                );
            })}
        </Carousel>
    );
};
