import Section from "../../Section";
import slashIcon from "./Slash.svg";
import { IServices } from "../../../models/Service";
import ReactMarkdown from "react-markdown";

const ServicesDetailed: React.FC<{ services: IServices | undefined }> = (props) => {
    const { services } = props;
    let i = 0;
    function formatNumberWithLeadingZero(num: number): string {
        return num.toString().padStart(2, '0');
    }
    return (
        <Section id="services-detailed" textColor="white" contactColor="red">
            {services &&
                services.data.map(service => {
                    return (
                        <div id={service.attributes.slug} className="service" key={service.id}>
                            <div className="title-container">
                                <p>{formatNumberWithLeadingZero(++i)}</p>
                                <div className="name-container">
                                    <h3 className="service-name">
                                        <ReactMarkdown className="line-break" children={service.attributes.title_details || service.attributes.title}
                                            components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                                        />
                                    </h3>
                                    <img src={slashIcon} alt="" />
                                </div>
                                <h3>
                                    <ReactMarkdown className="line-break" children={service.attributes.title_appendix || ''}
                                        components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                                    />
                                </h3>
                            </div>
                            <div className="content">
                                <div className="services-list">
                                    <ReactMarkdown children={service.attributes.description_list.replaceAll("-", "\n") || ''}
                                        components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                                    />
                                </div>
                                <div className="services-description">
                                    <p>
                                        <ReactMarkdown children={service.attributes.description}
                                            components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="content">
                                <div className="services-list">
                                    <ReactMarkdown className="line-break" children={service.attributes.content_left || ''}
                                        components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                                    />
                                </div>
                                <div className="services-description">
                                    <ReactMarkdown className="line-break" children={service.attributes.content_right || ''}
                                        components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
        </Section>
    );
}

export default ServicesDetailed;
