import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import { ToggleContact } from "../actions/pageActions";
import ContactUs from "./Landing/Sections/ContactUs";
import logoGrey from "./Logotip.svg";
import logoWhite from "./Logotip_white.svg";
import logoMGrey from "./Logotip_mobile.svg";
import logoMWhite from "./Logotip_mobile_white.svg";
import { Uprise } from "./Landing/Sections/Uprise";
import "./Section.less";

export interface SectionProps {
    textColor?: "grey" | "white";
    logoColor?: string;
    contactColor?: "yellow" | "red" | "green";
    id?: string;
    exitContact?: boolean;
    logoLink?: boolean;
    children: ReactNode;
    className?: string;
}

function Section({
    children,
    textColor = "white",
    logoColor = undefined,
    contactColor = "yellow",
    exitContact = false,
    logoLink = true,
    className,
    ...props
}: SectionProps) {
    const dispatch = useDispatch();

    const toggleContact = () => {
        dispatch(ToggleContact());
    };

    logoColor = logoColor ?? textColor;
    const logo: string = logoColor === "white" ? logoWhite : logoGrey;
    const logom: string = logoColor === "white" ? logoMWhite : logoMGrey;

    return (
        <section
            className={`project-42-section ${textColor} ${className ?? ""}`}
            style={{ color: textColor }}
            {...props}
        >
            <div className="fixed-holder top">
                <Link className={`logo scroll-hide ${logoLink ? "" : "no-click"}`} to="/#home">
                    <img className="mobile-logo" src={logom} alt="logo" />
                    <img className="desktop-logo" src={logo} alt="logo" />
                </Link>
            </div>
            <div className="fixed-holder">
                <div className={`contact-holder ${contactColor}`} onClick={toggleContact}>
                    <ContactUs borderColor={logoColor} isExit={exitContact} />
                    <p style={{ color: logoColor }}>contact us</p>
                </div>

                <div className={`uprise-holder ${textColor}`}>
                    <p>Powered by </p>
                    <Uprise />
                </div>
            </div>
            <div className="container">{children}</div>
        </section>
    );
}

export default Section;
