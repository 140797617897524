import Section from "../../../Section";
import { ProjectCard } from "../../../../components/ProjectCard/ProjectCard";
import { Link } from "react-router-dom";
import "./OurProjects.less";
import { IProjects } from "../../../../models/Project";
import { useCallback, useEffect, useState } from "react";
import { fetchLandingProjects } from "../../../../services/ProjectService";

function OurProjects() {
    const [projects, setProjectsList] = useState<IProjects>();
    const fetchData = useCallback(async () => {
        const svc: any = await fetchLandingProjects();
        setProjectsList(svc);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    let i = 0;
    function formatNumberWithLeadingZero(num: number): string {
        return num.toString().padStart(2, '0');
    }
    return (
        <Section id="work" textColor="white" contactColor="green">
            <h1>Projects</h1>
            <p>
                Regardless of the industry, our work stands out. Take a look at some of the projects we have delivered for our clients.
            </p>

            <div id="projects-container">
                {projects && projects.data.map(project => {
                    return (
                        <ProjectCard
                            key={project.attributes.slug}
                            name={project.attributes.title}
                            ordinal={formatNumberWithLeadingZero(++i)}
                            description={project.attributes.description}
                            image={project.attributes.image.data.attributes.formats.small.url}
                            link={project.attributes.slug}
                            type={project.attributes.type}
                        />
                    );
                })}
            </div>

            <h3>
                <Link to="/work">See more</Link>
            </h3>
        </Section>
    );
}

export default OurProjects;
