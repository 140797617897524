import { Quotes } from "../../../../components/Quotes/Quotes";
import Section from "../../../Section";
import { IQuote } from "../../../../models/Quote";
import "./QuotesSection.less";

const quotes: IQuote[] = [
    {
        content: `I have had the chance to cooperate with Project42 on a 
        complex project that involved a lot of subject-matter expertise. 
        The team really made a huge effort to learn everything related to 
        my industry, which resulted in a great piece of software that 
        really helps our everyday business operations.`,
        name: "Ivan Markić, Managing Director",
        credentials: "Prona-Grad",
    },
    {
        content: `Coming from a non-technical background, with just an 
        idea in my head, it was really important for me to find people 
        who could translate my thoughts into an app. Project42 are not
        just great professionals but also really nice to work with on a
        personal level.`,
        name: "Milan Belojević, Managing Director",
        credentials: "MB Capital Consulting GmbH",
    },
    {
        content: `What we loved most about the team was the communication. 
        Every step of the project was thoroughly explained and we didn’t
        feel left out of the decision-making. The final app was everything
        we asked for and more. The high level of code quality and the
        attention to details were very satisfying.`,
        name: "Dario Mišković, Managing Director",
        credentials: "Medical Yoga Center",
    },
    {
        content: `Given the complexity of the project and the little time 
        that we had for completing it, it was important to us to find a partner that
        we could count on in terms of speed and professionalism. Project42 exceeded
        our expectations. They went above and beyond to reach our deadlines and were
        very detail-oriented in the 3D representations of our products. We’ll be
        looking forward to collaborating with them again.`,
        name: "Luka Petro, Program Manager at DOK-ING",
        credentials: "DOK-ING",
    },
];

function QuotesSection() {
    return (
        <Section id="quotes" textColor="white" contactColor="green">
            <h1>See what our clients say about us</h1>
            <Quotes quotes={quotes} />
        </Section>
    );
}

export default QuotesSection;
