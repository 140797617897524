import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToggleContact } from "../../actions/pageActions";
import Section from "../Section";
import "./Footer.less";

function Footer() {
    const dispatch = useDispatch();

    const openContact = () => {
        dispatch(ToggleContact());
    };

    return (
        <footer>
            <Section id="footer-section" textColor="grey">
                <div id="menu-links">
                    <Link to="/">
                        <h2 className="red">Home</h2>
                    </Link>
                    <Link to="/services">
                        <h2 className="red">Services</h2>
                    </Link>
                    <Link to="/about">
                        <h2 className="green">About</h2>
                    </Link>
                    <Link to="/careers">
                        <h2 className="green">Careers</h2>
                    </Link>
                    <Link to="/work">
                        <h2 className="yellow">Work</h2>
                    </Link>
                    <h2 className="yellow" onClick={openContact}>
                        Contact
                    </h2>
                    <Link to="/blog">
                        <h2 className="red">Blog</h2>
                    </Link>
                </div>

                <div id="social-links">
                    <div id="social-media">
                        <a
                            href="https://www.facebook.com/project42.agency"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Facebook
                        </a>
                        <a
                            href="https://www.instagram.com/project42agency/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Instagram
                        </a>
                        <a
                            href="https://www.linkedin.com/company/project42agency/?viewAsMember=true"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LinkedIn
                        </a>
                    </div>
                </div>
            </Section>
            <hr />
            <div className="disclamer-container">
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/legal">Legal</Link>
                <p>©2022 Project42.agency</p>
            </div>
        </footer>
    );
}

export default Footer;
