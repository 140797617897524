import Section from "../../Section";
import "../Blog.less";
import { fetchBlogPosts } from "../../../services/BlogPostService";
import { IBlogPage } from "../../../models/BlogPost";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dateformat from "dateformat";

const Fade = require("react-reveal/Fade");

function AllPosts() {
    const [blogPage, setBlogPage] = useState<IBlogPage>();
    const fetchBlogPage = useCallback(async () => {
        const pst = await fetchBlogPosts();
        setBlogPage(pst);
    }, []);

    useEffect(() => {
        fetchBlogPage();
    }, [fetchBlogPage]);
    return (
        <Section id="posts-list" textColor="white">
            <h1>Blog</h1>

            {blogPage &&
                blogPage.data.map(post => {
                    return (
                        <div key={post.id} className="post">
                            <Fade up>
                                <div className="post-summary">
                                    <span>{dateformat(post?.attributes.publishedAt, "mmm dd, yyyy")}</span>
                                    <Link to={`blog/${post?.attributes.slug}`}>
                                        <h2>{post?.attributes.title}</h2>
                                    </Link>

                                    <p className="post-description">{post?.attributes.summary}</p>
                                </div>
                            </Fade>
                            {post?.attributes.image.data && (
                                <Fade up>
                                    <div className="image-container">
                                        <img
                                            className="post-image"
                                            src={post?.attributes.image.data.attributes.url}
                                            alt={post?.attributes.image.data.attributes.name}
                                        />
                                    </div>
                                </Fade>
                            )}
                        </div>
                    );
                })}
        </Section>
    );
}

export default AllPosts;
