import Section from "../../../Section";
import "./AboutUs.less";

import teamImg1 from "./images/Team1.jpg";
import teamImg2 from "./images/Team2.jpg";
import teamImg3 from "./images/Team3.jpg";

const Fade = require("react-reveal/Fade");

function AboutUs() {
    return (
        <Section id="about-us" textColor="white" contactColor="yellow">
            <h1>About Us</h1>
            <p>
                A collective of highly skilled individuals who enjoy all things
                tech. What started as just a few enthusiastic programmers is now a group of experts
                ranging from developers to business analysts and creatives of all sorts.
            </p>

            <Fade up>
                <img src={teamImg1} alt="" />
            </Fade>

            <div className="about-row reverse">
                <div className="text-description">
                    <h3>Skillful &amp; determined</h3>
                    <p>
                        Our team consists of young enthusiasts who bring creativity and very diverse
                        skillsets to the table. When we put our heads together, we find unexpected
                        and out-of-the-box solutions that exceed clients’ expectations. That means
                        we have a lot of tricks up our sleeve that you can’t get elsewhere.
                    </p>
                </div>
                <Fade up>
                    <div className="img-container">
                        <img src={teamImg2} alt="" />
                    </div>
                </Fade>
            </div>
            <div className="about-row">
                <div className="text-description">
                    <h3>Passionate &amp; dedicated</h3>
                    <p>
                        Enjoyment is the key ingredient in our work. We love setting new challenges
                        out for ourselves. This attitude translates into our relationships with
                        clients, and they feel our commitment.
                    </p>
                </div>
                <Fade up>
                    <div className="img-container">
                        <img src={teamImg3} alt="" />
                    </div>
                </Fade>
            </div>
        </Section>
    );
}

export default AboutUs;
