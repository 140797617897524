import ReactMarkdown from "react-markdown";
import { IProject } from "../../../../models/Project";
import Section from "../../../Section";

const ProjectHome: React.FC<{ project: IProject | null }> = (props) => {
    const { project } = props;
    let client = project?.attributes.home.client?.split("\n");
    let services = project?.attributes.home.services?.split("\n");
    let industry = project?.attributes.home.industry?.split("\n");
    let technology = project?.attributes.home.technology?.split("\n");
    return (
        <Section id="project-home" className="home" textColor="grey" contactColor="green">
            <h1>{project?.attributes.title}</h1>
            {project?.attributes.homeText && (
                <p>
                    <ReactMarkdown className="line-break" children={project?.attributes.homeText.replaceAll(/\n/gi, "&nbsp; \n").replaceAll("{endList}", "\n\n") || ''}
                        components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                    />
                </p>
            )}
            <div className="content">
                <div className="content-item">
                    <div className="head">CLIENT</div>
                    {client && client.map(row => {
                        return (
                            <div>{row}</div>
                        );
                    })
                    }
                </div>
                <div className="content-item">
                    <div className="head">SERVICES</div>
                    {services && services.map(row => {
                        return (
                            <div>{row}</div>
                        );
                    })
                    }
                </div>
                <div className="content-item">
                    <div className="head">INDUSTRY</div>
                    {industry && industry.map(row => {
                        return (
                            <div>{row}</div>
                        );
                    })
                    }
                </div>
                <div className="content-item">
                    <div className="head">TECHNOLOGY</div>
                    {technology && technology.map(row => {
                        return (
                            <div>{row}</div>
                        );
                    })
                    }
                </div>
            </div>
        </Section>
    );
}

export default ProjectHome;
