import { useEffect } from "react";
import ReactGA from "react-ga4";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import LinkedInTag from "react-linkedin-insight";
import "./CookieConsentPrompt.less";

export const CookieConsentPrompt = () => {
    const GOOGLE_ANALYTICS_ID = "G-QP92QY1BDS";
    const LINKEDIN_PARTNER_ID = "4917258";

    const handleAcceptCookie = () => {
        ReactGA.initialize(GOOGLE_ANALYTICS_ID);
        LinkedInTag.init(LINKEDIN_PARTNER_ID);
    };

    const handleDeclineCookie = () => {
        //remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
        Cookies.remove("ln_or");
        LinkedInTag.disabled = true;
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        } else {
            handleDeclineCookie();
        }
    }, []);

    return (
        <div id="cookie-wrapper">
            <CookieConsent
                containerClasses="cookie-banner"
                buttonWrapperClasses="cookie-buttons"
                contentClasses="cookie-content"
                buttonText="Accept"
                declineButtonText="Decline"
                disableStyles
                enableDeclineButton
                onAccept={handleAcceptCookie}
                onDecline={handleDeclineCookie}
            >
                <div>
                    This website uses cookies to ensure you get the best experience on our website.
                </div>
            </CookieConsent>
        </div>
    );
};
