import { useEffect } from "react";
import { Layout } from "./containers/Layout/Layout";
import { Route, Switch, BrowserRouter as Router, useLocation } from "react-router-dom";
import Landing from "./containers/Landing/Landing";
import Team from "./containers/Team/Team";
import Careers from "./containers/Careers/Careers";
import Work from "./containers/Work/Work";
import Services from "./containers/Services/Services";
import Legal from "./containers/Legal/Legal";
import Privacy from "./containers/Legal/Privacy";
import { ContactPage } from "./containers/Contact/ContactPage/ContactPage";
import Blog from "./containers/Blog/Blog";
import Post from "./containers/Post/Post";
import Project from "./containers/Projects/Project/Project";

// Scrolling on top when changing pages!
export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        document.querySelector("#scrollable")?.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <Router>
            <Layout>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/careers" component={Careers} />
                    <Route exact path="/about" component={Team} />
                    <Route exact path="/work" component={Work} />
                    <Route exact path="/services" component={Services} />
                    <Route exact path="/projects/:slug" component={Project} />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/blog/:slug" component={Post} />
                    <Route exact path="/legal" component={Legal} />
                    <Route exact path="/privacy" component={Privacy} />

                    <Route exact path="/contact-us" component={ContactPage} />

                    <Route exact path="/home" component={Landing} />
                    <Route component={Landing} />
                </Switch>
            </Layout>
        </Router>
    );
}

export default App;
