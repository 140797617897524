import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "../../../..";
import { initLongMessage, IShortMessage } from "../../../../models/Contact";
import { postShortMessage } from "../../../../services/ContactService";
import Section from "../../../Section";
import LinkedInTag from "react-linkedin-insight";

export const Home = () => {
    const LINKEDIN_CONVERSION_ID = "12472162";

    const { search } = useLocation();
    const history = useHistory();
    const [contactUs, setContactUs] = useState(initLongMessage);
    const [sendingDisabled, setSendingDisabled] = useState(true);
    const [currentlySending, setCurrentlySending] = useState(false);

    const handleSendMessage = async () => {
        try {
            setCurrentlySending(true);

            let params = new URLSearchParams(search);
            let tag = params.get("t");

            let shortMessageMock: IShortMessage = {
                name: contactUs.name,
                email: contactUs.email,
                aboutProject: `[Position]\n ${contactUs.position}\n\n[Industry]\n ${
                    contactUs.industry
                }\n\n[Message]\n${contactUs.message}\n\n\n[TAG]\n${tag ?? "No tag"}`,
            };
            const res = await postShortMessage(shortMessageMock);
            if (!res.ok) {
                Promise.reject(res);
            }

            setContactUs(initLongMessage);
            toast("Your message was sent successfully.\nThank you!", true, 5000);

            // Send conversion event to LinkedIn
            if (LinkedInTag.disabled) {
                LinkedInTag.track(LINKEDIN_CONVERSION_ID);
            }

            history.push("/home");
        } catch (err) {
            toast("It looks like something went wrong.\nPlase try again later!", true, 5000);
        } finally {
            setCurrentlySending(false);
        }
    };

    useEffect(() => {
        // If any of the fields are empty, disable the send button.
        setSendingDisabled(
            !contactUs.name ||
                !contactUs.email ||
                !contactUs.position ||
                !contactUs.industry ||
                !contactUs.message
        );
    }, [contactUs, setSendingDisabled]);

    return (
        <Section id="contact-home" textColor="grey">
            <div className="contact-greeting">
                <div className="greeting-content">
                    <div>
                        <h1>
                            <span className="red">
                                Need Animated <br />
                                Content?
                            </span>
                            <br />
                            <span className="yellow">We’ve Got</span>
                            <br />
                            <span className="green">You Covered.</span>
                        </h1>
                        <p>
                            Reach out to our team <b>today</b> and secure a <b>20%</b> discount on
                            all our 2D and 3D solutions.
                        </p>
                    </div>
                    <div className="contact-footer">
                        <span>
                            <a href="mailto:info@project42.agency">info@project42.agency</a>
                        </span>
                        <span>Project 42 Technologies d.o.o.</span>
                        <span>Trg Drage Iblera 10, 10 000 Zagreb</span>
                    </div>
                </div>
                <div className="contact-form">
                    <div className="contact">
                        <input
                            placeholder="* What's your name?"
                            value={contactUs.name}
                            onChange={e => setContactUs({ ...contactUs, name: e.target.value })}
                        />
                        <input
                            placeholder="* What's your work email?"
                            value={contactUs.email}
                            onChange={e => setContactUs({ ...contactUs, email: e.target.value })}
                        />
                        <input
                            placeholder="* What's your position?"
                            value={contactUs.position}
                            onChange={e => setContactUs({ ...contactUs, position: e.target.value })}
                        />
                        <input
                            placeholder="* What's your industry?"
                            value={contactUs.industry}
                            onChange={e => setContactUs({ ...contactUs, industry: e.target.value })}
                        />
                        <textarea
                            rows={5}
                            placeholder="* Leave us a message :)"
                            value={contactUs.message}
                            onChange={e => setContactUs({ ...contactUs, message: e.target.value })}
                        />

                        <div className="tooltip-wrapper">
                            <div
                                hidden={!sendingDisabled || currentlySending}
                                className={`tooltip ${currentlySending ? "force-visible" : ""}`}
                            >
                                Please fill all of the fields above before sending.
                            </div>
                            <div hidden={!currentlySending} className="tooltip">
                                Your message is being sent!
                            </div>
                            <span
                                role="button"
                                className={`tooltip-target ${
                                    sendingDisabled || currentlySending ? "disabled" : ""
                                }`}
                                onClick={handleSendMessage}
                            >
                                Send
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};
