import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { HideToastMessage, SetToastMessage, ShowToastMessage } from "./actions/toastMessageActions";
import "./index.less";

const store = configureStore();

export const toast = (text: string, success: boolean, timeout: number = 3000) => {
    const dispatch = store.dispatch;
    dispatch(SetToastMessage(text, success));
    dispatch(ShowToastMessage());
    setTimeout(() => dispatch(HideToastMessage()), timeout);
};

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
