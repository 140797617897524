import AllPosts from "./Sections/AllPosts";
import "./Blog.less";
function Blog() {
    return (
        <div id="posts">
            <AllPosts />
        </div>
    );
}

export default Blog;
