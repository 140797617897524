import ReactPlayer from "react-player";
import { IProject } from "../../../../models/Project";
import Section from "../../../Section";
import ReactMarkdown from "react-markdown";

const Fade = require("react-reveal/Fade");
const ProjectDesign: React.FC<{ project: IProject | null }> = (props) => {
    const { project } = props;
    let colors = project?.attributes.colors?.split("\n");
    return (
        <Section id="project-design" className="design" contactColor="green">
            {project?.attributes.designVideo.data &&
                (<div>
                    <h2>Animation</h2><Fade up>
                        <div className="player-wrapper">
                            <ReactPlayer
                                url={project?.attributes.designVideo?.data.attributes.url.startsWith("https://") ? project?.attributes.designVideo?.data.attributes.url : `https://${project?.attributes.designVideo?.data.attributes.url}`}
                                className="react-player"
                                width="100%"
                                height="100%"
                                controls={true} />
                        </div>
                    </Fade>
                </div>)
            }
            {(project?.attributes.colors && project.attributes.fonts) &&
                (<div>
                    <h2>Colors</h2>

                    <div className="colors-container">
                        {colors && colors.map(color => {
                            return (
                                <div key={color}>
                                    <div style={{ backgroundColor: color }} className="color-dot" />
                                    <p>{color}</p>
                                </div>
                            );
                        })}
                    </div>
                    <h2>Font</h2>
                    <ReactMarkdown className="line-break" children={project?.attributes.fonts.replaceAll(/\n/gi, "&nbsp; \n").replaceAll("{endList}", "\n\n") || ''}
                        components={{ img: ({ node, ...props }) => <img style={{ maxHeight: '90px' }}{...props} /> }}
                    />
                    <h2>Responsive design</h2>
                </div>)
            }
        </Section>
    );
}

export default ProjectDesign;
