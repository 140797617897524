import Section from "../../Section";
import { ProjectCard } from "../../../components/ProjectCard/ProjectCard";
import "../Work.less";
import { useCallback, useEffect, useState } from "react";
import { IProjects } from "../../../models/Project";
import { fetchProjects } from "../../../services/ProjectService";

function AllProjects() {
    const [projects, setProjectsList] = useState<IProjects>();
    const fetchData = useCallback(async () => {
        const svc: any = await fetchProjects();
        setProjectsList(svc);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    let i = 0;
    function formatNumberWithLeadingZero(num: number): string {
        return num.toString().padStart(2, '0');
    }
    return (
        <Section id="project-list" textColor="white">
            <h1>Our projects</h1>

            {projects &&
                projects.data.map(project => {
                    return (
                        <ProjectCard
                            key={project.attributes.slug}
                            name={project.attributes.title}
                            ordinal={formatNumberWithLeadingZero(++i)}
                            description={project.attributes.description}
                            image={project.attributes.image.data.attributes.url}
                            link={project.attributes.slug}
                            type={project.attributes.type}
                        />
                    );
                })}
        </Section>
    );
}

export default AllProjects;
