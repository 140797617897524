import Section from "../../../Section";
import "./TeamHome.less";

function TeamHome() {
    return (
        <Section id="team-home" textColor="grey">
            <h1>An agency to be reckoned with.</h1>
            <div>
                <p>
                    Project42 is your go-to agency for high quality outsourcing of all your tech
                    needs.
                </p>
            </div>
        </Section>
    );
}

export default TeamHome;
