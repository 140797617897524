import TeamHome from "./Sections/TeamHome/TeamHome";
import AboutUs from "./Sections/AboutUs/AboutUs";
import OurStory from "./Sections/OurStory/OurStory";
import QuotesSection from "./Sections/QuotesSection/QuotesSection";

function Team() {
    return (
        <>
            <TeamHome />
            <AboutUs />
            <OurStory />
            <QuotesSection />
        </>
    );
}

export default Team;
