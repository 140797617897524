import dotenv from 'dotenv';

dotenv.config();
const strapiBaseUrl = process.env.REACT_APP_STRAPI_API_URL;
const accessToken = process.env.REACT_APP_ACCESS_TOKEN;

const servicesEndpoint = `${strapiBaseUrl}/services`;

const fetchConfig = {
    headers: {
        Authorization: `Bearer ${accessToken}`,
    },
};

export async function fetchServices() {
    try {
        const response = await fetch(`${servicesEndpoint}?populate=*&sort[1]=publishedAt:desc`, fetchConfig);
        if (!response.ok) {
            throw new Error('Failed to fetch data from Strapi backend.');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}