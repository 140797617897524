import Section from "../Section";
import "./Legal.less";

function Legal() {
    return (
        <Section id="legal" className="legal-section" textColor="grey">
            <h1>Legal info</h1>

            <p>Project 42 is a limited company dedicated to information technology services, development, design and animation.</p>
            <p>
                The company is registered with the Commercial Court in Zagreb under registration number MBS 081437129.
            </p>
            <p>Base capital of the company of 20.000,00 has been paid in full.</p>
            <p>Members of the Management Board: Dino Mileta.</p>

            <br />

            <p>Project42 Technologies d.o.o.</p>
            <p>HQ: Petrova ulica 15, Zagreb</p>
            <p>Office: Trg Drage Iblera 10, Zagreb</p>
            <p>Email: info@project42.agency</p>
            <p>Phone: (+385)989669587</p>
            <p>OIB: 68013671772</p>
            <p>IBAN: HR3524020061101094704</p>
            <p>Name of Bank: Erste &amp; Steiermarkische Bank d.d</p>
        </Section>
    );
}

export default Legal;
