import dotenv from 'dotenv';

dotenv.config();

const strapiBaseUrl = process.env.REACT_APP_STRAPI_API_URL;
const accessToken = process.env.REACT_APP_ACCESS_TOKEN;


const articlesEndpoint = `${strapiBaseUrl}/posts`;

const fetchConfig = {
    headers: {
        Authorization: `Bearer ${accessToken}`,
    },
};

export async function fetchBlogPosts() {
    try {
        const response = await fetch(`${articlesEndpoint}?fields[0]=title&fields[1]=slug&fields[2]=summary&fields[3]=publishedAt&populate[image][fields][0]=name&populate[image][fields][1]=url&sort[1]=publishedAt:desc`, fetchConfig);
        if (!response.ok) {
            throw new Error('Failed to fetch data from Strapi backend.');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export async function fetchSinglePost(slug: string) {
    try {
        const endpointUrl = `${articlesEndpoint}?filters[slug][$eq]=${slug}&populate=*`;

        const response = await fetch(endpointUrl, fetchConfig);
        if (!response.ok) {
            throw new Error('Failed to fetch data from Strapi backend.');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

