import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IPost } from "../../models/BlogPost";
import Section from "../Section";
import "../Section.less";
import { fetchSinglePost } from "../../services/BlogPostService";
import "./Post.less";
import dateformat from "dateformat";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

const Fade = require("react-reveal/Fade");
const Post: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [post, setPost] = useState<IPost | null>(null);

    const fetchPost = useCallback(async () => {
        const pst = await fetchSinglePost(slug);
        setPost(pst.data[0]);
    }, [slug]);

    useEffect(() => {
        fetchPost();
    }, [fetchPost]);
    return (
        <div className="post-content">
            <Helmet>
                <meta property="og:title" content={post?.attributes.title} />
                <meta property="og:description" content={post?.attributes.summary} />
                <meta property="og:image" content={post?.attributes.image.data.attributes.url} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary" />
                <meta property="twitter:url" content={window.location.href} />
                <meta name="twitter:title" content={post?.attributes.title} />
                <meta
                    name="twitter:description"
                    content={post?.attributes.summary}
                />
                <meta
                    name="twitter:image"
                    content={post?.attributes.image.data.attributes.url}
                />
            </Helmet>
            <Section id="cipka-home" className="home" textColor="grey" contactColor="green">
                {post?.attributes.image.data && (
                    <Fade up>
                        <img
                            className="post-img"
                            src={post?.attributes.image.data.attributes.formats.small.url}
                            alt=""
                        />
                    </Fade>
                )}
                <h1>{post?.attributes.title}</h1>
                <span>{dateformat(post?.attributes.publishedAt, "mmm dd, yyyy")}</span>
                <p>
                    <ReactMarkdown className="line-break" children={post?.attributes.content.replaceAll(/\n/gi, "&nbsp; \n").replaceAll("{endList}", "\n\n") || ''}
                        components={{ img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }}{...props} /> }}
                    />
                </p>
            </Section>
        </div>
    );
}

export default Post;
