import { Link } from "react-router-dom";
import "./ProjectCard.less";

const Fade = require("react-reveal/Fade");

export interface ProjectProps {
    name: string;
    ordinal: string;
    description: string;
    image: string;
    link?: string;
    type: string;
}

export const ProjectCard = (props: ProjectProps) => {
    return (
        <Fade up>
            <div id={props.name} className="project">
                <div className="project-summary">
                    <p>{"[" + props.ordinal + "] " + props.type}</p>
                    {props.link ? (
                        <Link to={"projects/" + props.link}>
                            <h2>{props.name}</h2>
                        </Link>
                    ) : (
                        <h2>{props.name}</h2>
                    )}
                    <p className="project-description">{props.description}</p>
                </div>
                {props.link ? (
                    <Link to={"projects/" + props.link}>
                        <img src={props.image} alt="" className="project-image" />
                    </Link>
                ) : (
                    <h2><img src={props.image} alt="" className="project-image" /></h2>
                )}
            </div>
        </Fade>
    );
};
