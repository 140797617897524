import Section from "../../../Section";
import teamImg1 from "./images/Team1.jpg";
import teamImg2 from "./images/Team2.jpg";
import teamImg3 from "./images/Team3.jpg";
import creativeImg from "./images/Creative.jpg";
import DinoIcon from "./images/AvatarDino.svg";
import FilipIcon from "./images/AvatarFilip.svg";
import MirkoIcon from "./images/AvatarMirko.svg";
import FilipGIcon from "./images/AvatarFilipG.svg";
import "./WhoWeAre.less";

const Fade = require("react-reveal/Fade");

function WhoWeAre() {
    return (
        <Section id="team" textColor="white" contactColor="red">
            <h1>Who we are</h1>
            <p>
                In 1979 Douglas Adams published The Hitchhiker's Guide to the Galaxy. In this comedy SF he used the number 42 as an answer to the ultimate question of life, universe and everything. 
                To us Adam's answer has signified that many of our unknowns are due to ways in which we formulate questions. And that's why we've named our digital agency Project42. 
                We don't presume to have all the answers. Instead, we strive to ask the right questions.
            </p>

            <div id="team-images">
                <Fade up>
                    <img className="half-width-desktop" src={teamImg1} alt="" />
                </Fade>
                <Fade up>
                    <img className="half-width-desktop" src={teamImg2} alt="" />
                </Fade>
                <Fade up>
                    <img src={creativeImg} alt="" />
                </Fade>
                <Fade up>
                    <img src={teamImg3} alt="" />
                </Fade>
            </div>

            <h3 id="team-intro">Meet some of our team</h3>
            <div id="team-icons">
                <Fade up>
                    <div>
                        <img src={DinoIcon} alt="CEO icon" />
                        <div className="team-titles">
                            <h3>Dino</h3>
                            <p>CEO</p>
                        </div>
                    </div>
                </Fade>
                <Fade up>
                    <div>
                        <img src={MirkoIcon} alt="Creative Director icon" />
                        <div className="team-titles">
                            <h3>Mirko</h3>
                            <p>Creative</p>
                            <p>Director</p>
                        </div>
                    </div>
                </Fade>
                <Fade up>
                    <div>
                        <img src={FilipIcon} alt="Development Team Lead icon" />
                        <div className="team-titles">
                            <h3>Filip</h3>
                            <p>Development</p>
                            <p>Team Lead</p>
                        </div>
                    </div>
                </Fade>
                <Fade up>
                    <div>
                        <img src={FilipGIcon} alt="Technical Director icon" />
                        <div className="team-titles">
                            <h3>Filip</h3>
                            <p>Technical</p>
                            <p>Director</p>
                        </div>
                    </div>
                </Fade>
            </div>
        </Section>
    );
}

export default WhoWeAre;
