export const Uprise = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="199.49 71.74 749.23 388.67"
        >
            <g>
                <path
                    className="cls-1"
                    d="M262.09,409.95c-38.98,0-62.6-24.19-62.6-64.31v-89.63h35.28v88.49c0,21.06,9.96,32.44,27.32,32.44s27.32-11.38,27.32-32.44v-88.49h35.28v89.63c0,40.12-23.33,64.31-62.6,64.31Z"
                />
                <path
                    className="cls-1"
                    d="M355.52,256.01h31.3l.28,18.21c10.53-13.37,29.02-21.06,48.66-21.06,40.69,0,74.83,31.87,74.83,78.25s-34.15,77.97-74.83,77.97c-17.93,0-35-6.26-45.81-17.64.57,11.38.85,23.62.85,35.28v33.39h-35.28v-204.4ZM431.21,376.94c25.32,0,43.82-18.5,43.82-45.53s-18.5-45.81-43.82-45.81-42.97,16.79-42.97,45.81,18.78,45.53,42.97,45.53Z"
                />
                <path
                    className="cls-1"
                    d="M586.85,256.01h18.21v31.87h-14.8c-12.8,0-17.93,5.12-17.93,18.21v100.44h-35.28v-99.59c0-35.28,17.64-50.93,49.79-50.93Z"
                />
                <path className="cls-1" d="M627.83,256.01h35.28v150.52h-35.28v-150.52Z" />
                <path
                    className="cls-1"
                    d="M693.84,406.53v-30.45c11.95,1.71,21.63,2.28,28.17,2.28,15.65,0,22.76-4.55,22.76-13.94,0-7.4-4.55-12.8-16.5-21.06l-9.39-6.54c-18.5-12.81-26.46-24.76-26.46-41.26,0-25.89,19.35-41.54,52.36-41.54,6.83,0,15.08.57,23.62,1.99v27.88c-8.54-1.14-15.08-1.42-19.06-1.42-15.08,0-22.76,4.55-22.76,12.52,0,5.69,4.55,10.53,16.22,18.21l9.11,6.26c20.49,13.94,29.02,27.03,29.02,44.67,0,27.89-21.06,44.39-57.19,44.39-7.68,0-18.78-.85-29.88-1.99Z"
                />
                <path
                    className="cls-1"
                    d="M799.68,331.7c0-46.1,33.01-78.82,76.54-78.82,39.84,0,75.69,27.03,72.27,86.22h-115.81c2.85,23.9,20.77,40.41,46.38,40.41,18.78,0,34.71-8.82,45.81-25.04l23.33,18.5c-14.8,21.34-38.13,36.99-70,36.99-46.95,0-78.53-33.01-78.53-78.25ZM915.21,313.77c-4.27-21.06-19.35-31.87-39.27-31.87-21.06,0-36.99,13.09-42.11,31.87h81.38Z"
                />
            </g>
            <path
                className="cls-2"
                d="M645.47,153.62c-22.57,0-40.94-18.37-40.94-40.94s18.37-40.94,40.94-40.94,40.94,18.37,40.94,40.94-18.37,40.94-40.94,40.94ZM645.47,89.97c-12.52,0-22.71,10.19-22.71,22.71s10.19,22.71,22.71,22.71,22.71-10.19,22.71-22.71-10.19-22.71-22.71-22.71Z"
            />
        </svg>
    );
};
