import { HashLink as Link } from "react-router-hash-link";
import Section from "../../../Section";
import "./WhatWeDo.less";
import { useCallback, useEffect, useState } from "react";
import { IServices } from "../../../../models/Service";
import { fetchServices } from "../../../../services/ServiceService";

const Fade = require("react-reveal/Fade");

function WhatWeDo() {
    const [services, setServicesList] = useState<IServices>();
    const fetchData = useCallback(async () => {
        const svc: any = await fetchServices();
        setServicesList(svc);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return (
        <Section id="about" textColor="white">
            <h1>Our services</h1>
            <p>
                To guide you through the extraordinary possibilities of the digital world, we have created a unique service offering.
            </p>

            <div className="items-container">
                {services &&
                    services.data.map(service => {
                        return (
                            <Fade up key={service.id}>
                                <div className="item">
                                    <img src={service.attributes.image.data.attributes.url} alt="" />
                                    <Link to={"/services#" + service.attributes.slug}>
                                        <h3>{service.attributes.title}</h3>
                                    </Link>
                                </div>
                            </Fade>
                        );
                    })}
            </div>
        </Section>
    );
}

export default WhatWeDo;
