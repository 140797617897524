export interface IProjectInfo {
    interestedSelected: string[];
    bugetSelected: string;
    aboutUsSelected: string;
    customerName: string;
    companyName: string;
    email: string;
    aboutProject: string;
}

export interface IShortMessage {
    name: string;
    email: string;
    aboutProject: string;
}

export const initShortMessage: IShortMessage = {
    name: "",
    email: "",
    aboutProject: "",
};

export interface ILongMessage {
    name: string;
    email: string;
    position: string;
    industry: string;
    message: string;
}

export const initLongMessage: ILongMessage = {
    name: "",
    email: "",
    position: "",
    industry: "",
    message: "",
};
