import ProjectDesign from "./Sections/ProjectDesign";
import ProjectHome from "./Sections/ProjectHome";
import ProjectScope from "./Sections/ProjectScope";
import "../../Section.less";
import "../Projects.less";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { IProject } from "../../../models/Project";
import { fetchSingleProject } from "../../../services/ProjectService";

function Project() {
    const { slug } = useParams<{ slug: string }>();
    const [project, setProject] = useState<IProject | null>(null);

    const fetchProject = useCallback(async () => {
        const pjt = await fetchSingleProject(slug);
        setProject(pjt.data[0]);
    }, [slug]);

    useEffect(() => {
        fetchProject();
    }, [fetchProject]);
    return (
        <div id="Project" className="project-main">
            <ProjectHome project={project} />
            <ProjectScope project={project} />
            <ProjectDesign project={project} />
        </div>
    );
}

export default Project;
