import { HashLink as Link } from "react-router-hash-link";
import Section from "../../Section";
import { IServices } from "../../../models/Service";
import ReactMarkdown from "react-markdown";

const ServicesList: React.FC<{ services: IServices | undefined }> = (props) => {
    const { services } = props;
    return (
        <Section id="services-list" textColor="white" contactColor="green">
            <h1>Services</h1>
            {services &&
                services.data.map(service => {
                    return (
                        <div className="item" key={service.id}>
                            <img src={service.attributes.image.data.attributes.url} alt="" />
                            <Link to={"#" + service.attributes.slug} >
                                <h3>
                                    {service.attributes.title}
                                </h3>
                            </Link>
                            <ReactMarkdown>{service.attributes.description_list || ''}</ReactMarkdown>
                        </div>
                    );
                })}
        </Section>
    );
}

export default ServicesList;
