import ServicesHome from "./Sections/ServicesHome";
import ServicesList from "./Sections/ServicesList";
import ServicesDetailed from "./Sections/ServicesDetailed";
import "./Services.less";
import { IServices } from "../../models/Service";
import { useCallback, useEffect, useState } from "react";
import { fetchServices } from "../../services/ServiceService";

function Services() {
    const [servicesList, setServicesList] = useState<IServices>();
    const fetchData = useCallback(async () => {
        const svc: any = await fetchServices();
        setServicesList(svc);
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return (
        <div id="services">
            <ServicesHome />
            <ServicesList services={servicesList} />
            <ServicesDetailed services={servicesList} />
        </div>
    );
}

export default Services;
